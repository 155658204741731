import React from "react";
import Loadable from "@loadable/component";
import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import ShopNav from "../components/shopNav";

import Layout from "../components/layout";

import "../styles/shop.scss";

const ShopPage = ({ data }) => {
  const ShopifyCollection = Loadable(
    () => import("../components/shopifyCollection"),
    {
      fallback: <div className="loading" />,
    }
  );

  return (
    <Layout pageTitle="Shop">
      <ShopNav />
      {data.sanityShop._rawText !== null && (
        <div className="text">
          <BlockContent blocks={data.sanityShop._rawText} />
        </div>
      )}
      <div className="shop-container">
        <ShopifyCollection
          collectionId={data.sanityShop.mainShopifyCollectionId}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    sanityShop(_id: { eq: "single-shop" }) {
      _rawText
      mainShopifyCollectionId
    }
  }
`;

export default ShopPage;
